<template>
  <div class="checkbox-tree-options">
    <EnInputCheckbox :single="true" :label="label" v-model="returnValue" />
    <EnInputCheckboxTreeOptions v-for="(option, i) in options" :key="i" :label="option.label" :options="option.children || false" />
  </div>
</template>

<script>
import EnInputCheckbox from './EnInputCheckbox.vue';
export default {
  name: 'EnInputCheckboxTreeOptions',
  components: {
    EnInputCheckbox,
    EnInputCheckboxTreeOptions: () => import('./EnInputCheckboxTreeOptions.vue')
  },
  props: {
    value: {
      default: false
    },
    label: String,
    options: [Array, Boolean]
  },
  data() {
    return {

    }
  },
  computed: {
    returnValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        return val;
      }
    }
  },
}
</script>

<style lang="scss">
  .checkbox-tree-options {
    padding-left: 20px;
  }
</style>
