import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue';
import Selects from '../views/Selects.vue';
import Masked from '../views/Masked.vue';
import DateInputs from '../views/DateInputs.vue';
import FileUpload from '../views/FileUpload.vue';


Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, name: 'Home' },
  { path: '/selects', component: Selects, name: 'Selects' },
  { path: '/date-inputs', component: DateInputs, name: 'Date Inputs' },
  { path: '/masked', component: Masked, name: 'Masked' },
  { path: '/file-upload', component: FileUpload, name: 'File Upload' },
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
export { routes }
