function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const optionsMixin = {
  props: {
    /**
     * Sets a different key to use to retrieve the value from the options passed to the component.
     */
    valueKey: {
      type: String,
      default: 'value'
    },
    /**
     * Sets a different key to use to retrieve the label from the options passed to the component.
     */
    labelKey: {
      type: String,
      default: 'label'
    },
    /**
     * Sets a different key to use to retrieve the options from the option groups passed to the component.
     */
    optionsKey: {
      type: String,
      default: 'options'
    },
    labelColors: {
      type: Array,
      default(){
        return [
          "#3f5aa8",
          "#4e0c08",
          "#3d7748",
          "#a50fa9",
          "#0b3911",
          "#aa4c5c",
          "#0e1c67",
          "#766762",
          "#d6061a",
          "#562fff",
          "#b34a01",
          "#cc156d",
          "#866609"
        ];
      }
    }
  },
  mounted() {

  },
  computed: {
    mappedOptions() {
      return this.mapOptions(this.options)
    },
    flatOptions() {
      return this.mappedOptions.map((option) => {
        if (option.options) {
          return [
            {
              group: true,
              label: option.label
            },
            ...option.options
          ]
        }
        return option
      }).flat()
    },
    selectedOptions() {
      const labels = []

      if (typeof this.displayValue === 'object') {
        this.flatOptions.forEach((option) => {
          if (this.displayValue.indexOf(option.value) !== -1) {
            labels.push(option)
          }
        })
      } else {
        this.flatOptions.forEach(option => {
          if (this.displayValue == option.value) {
            labels.push(option);
          }
        });
      }
      return labels
    },
    optionsRules() {
      // const values = this.flatOptions.map(o => o.value)
      // const valuesString = values.join(',')
      // return `${this.rules}|oneOf:${valuesString}`
      return this.rules
    }
  },
  methods: {
    mapOptions(options) {
      return options.map((option, index) => {
        if (typeof option === 'object') {

          // get label and disabled attr if exists
          const capsLabelKey = capitalizeFirstLetter(this.labelKey)
          const labelKey = option.hasOwnProperty(this.labelKey)
            ? this.labelKey
            : option.hasOwnProperty(capsLabelKey) ? capsLabelKey : 'no label key found';
          const disabled = (option.Disabled || option.disabled)


          const opt = {
            label: option[labelKey],
            disabled,
          }

          const capsOptionsKey = capitalizeFirstLetter(this.optionsKey)
          const optionsKey = option.hasOwnProperty(this.optionsKey)
            ? this.optionsKey
            : option.hasOwnProperty(capsOptionsKey) ? capsOptionsKey : '';

          if (optionsKey) {
            opt.options = this.mapOptions(option[optionsKey])
            return opt
          }

          const capsValueKey = capitalizeFirstLetter(this.valueKey)
          const valueKey = option.hasOwnProperty(this.valueKey)
            ? this.valueKey
            : option.hasOwnProperty(capsValueKey) ? capsValueKey : 'no value key found';

          opt.value = option[valueKey] === null ? "" : option[valueKey]
          opt.labelColor = option.labelColor || this.labelColors[index]

          return opt

        } else {
          return {
            label: option,
            value: option
          }
        }
      })
    }
  }
}

export { optionsMixin as default };
