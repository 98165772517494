<template>
  <div v-if="displayOnly" class="form-control-static"><slot name="display" :value="displayValue">{{ displayValue }}</slot></div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="rules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <div class="en-datepicker" :class="{'input-group': !inline}">
      <DatePicker ref="datepicker" v-model="displayValue" v-bind="options" @input="onInput">
        <template v-if="!inline" v-slot="{ inputValue, inputEvents }">
          <input
            class="form-control"
            type="text"
            :name="name"
            :id="id || name"
            :required="required"
            :placeholder="placeholder"
            :disabled="disabled"
            :value="inputValue"
            v-on="inputEvents"
            @blur="handleBlur($event)"
          />
        </template>
        <template #footer v-if="showTodayButton">
          <div class="display-flex justify-center">
            <button class="btn btn-default today-btn" @click="selectToday(true)" type="button">Today</button>
          </div>
        </template>
      </DatePicker>

      <span v-if="!inline" class="input-group-addon icon-calendar"></span>
    </div>
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import EnInputText from "./EnInputText.vue";
import inputMixin from "../utils/input-mixin.js";
import DatePicker from "@front-end/v-calendar/src/lib-components/DatePicker.vue";
import '@front-end/v-calendar/dist/v-calendar.css'

export default {
  name: "EnInputDatepicker",
  components: {
    EnValidationFieldWrapper,
    EnInputText,
    DatePicker,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Use v-model to bind to value of the input.
     * @model
     */
    value: {
      type: [String, Date],
    },
    /**
     * Accepts a string to format the returned date value based on the mask values below.
     */
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY'
    },
    /**
     * Setting to true will set the input value to today's date on mount.
     * Since this is set in the mounted event, updating this on an alreayd mounted component will not work.
     * See 'selectToday' method instead.
     */
    defaultToday: {
      type: Boolean,
      default: false
    },
    /**
     * Highlights todays date on the calendar.
     */
    highlightToday: {
      type: Boolean,
      default: false
    },
    /**
     * Adds a button at the bottom of the calendar to automatically select today's date.
     */
    showTodayButton: {
      type: Boolean,
      default: false
    },
    /**
     * Displays the datepicker as a calendar without a text input.
     */
    inline: {
      type: Boolean,
      default: false
    },
    /**
     * Accepts an object of settings for the v-calendar datepicker/calendar component.
     * See https://vcalendar.io/api/v2.0/calendar.html#props and https://vcalendar.io/api/v2.0/datepicker.html#props
     */
    datepickerOptions: {
      type: Object,
      default() {
        return {

        }
      }
    }
  },
  mounted() {
    if (this.defaultToday) {
      this.selectToday()
    }
  },
  computed: {
    options() {
      const attributes = this.datepickerOptions.attributes ? this.datepickerOptions.attributes : []
      if (this.highlightToday) {
        attributes.push({
          key: 'today',
          highlight: {
            fillMode: 'outline',
          },
          dates: new Date(),
        },)
      }

      return {
        modelConfig: {
          type: 'string',
          mask: this.dateFormat,
        },
        popover: {
          visibility: 'focus'
        },
        ...this.datepickerOptions,
        attributes
      }
    }
  },
  methods: {
    handleBlur(e) {
      if (this.required && (!e.relatedTarget || e.relatedTarget.className.indexOf('vc') === -1)) {
        this.validate(e)
      }
    },
    /**
     * Updates selected value to today.
     * @public
     */
    selectToday(close = false) {
      this.$refs.datepicker.updateValue(new Date())
      this.$refs.datepicker.$refs.calendar.move(new Date())

      if (close) {
        this.$refs.datepicker.hidePopover()
      }
    },
    onInput(v) {
      if (v.length) {
        this.$refs.datepicker.hidePopover()
      } else {
        this.$refs.datepicker.showPopover()
      }
    }
  }
};
</script>
<style lang="scss">
  .en-datepicker {

    .vc-container .vc-container {
      background: transparent;
    }

    .vc-select-arrow {
      display: none;
    }

    .today-btn {
      border: 0;
      padding: 4px 6px;
      margin-bottom: 4px;
    }

    select {
      background-position: right 6px center;
    }
  }

</style>
