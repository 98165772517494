<template>
  <div :class="classes">
    <label :for="id" :class="{label: pills}" :disabled="disabled">
      <input
        type="radio"
        :name="name"
        :value="optionValue"
        :disabled="disabled"
        v-model="displayValue"
        :id="id || name"
        :required="required"
      />
      {{ label }} <slot name="tooltip" />
    </label>
  </div>
</template>

<script>
import inputMixin from "../utils/input-mixin.js";
export default {
  name: "EnInputRadio",
  mixins: [inputMixin],
  props: {
    value: {
      default: null,
    },
    optionValue: {
      default: null,
    },
    label: String,
    inline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    pills: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        radio: !this.inline,
        "radio-inline": this.inline,
      };
    },
  },
};
</script>

<style lang="scss">
.radio {
  &:first-child {
    margin-top: 0;
  }
}
</style>
