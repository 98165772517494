export const inputMixin = {
  props: {
    /**
    * Use v-model to bind to value of the input.
    * @model
    */
    value: {
      type: [String, Number]
    },
    /**
    * Sets name attribute for the input.  Required for proper form handling.
    */
    name: {
      type: String,
      default: null
    },
    /**
    * Sets id attribute for input.  This should be matched to a label's "for" attribute for proper accessibility.
    */
    id: {
      type: String,
      default: null
    },
    /**
    * Sets the placeholder value.
    */
    placeholder: {
      type: String,
      default: null
    },
    /**
    * [Not currently used but may be supported at some point] Used to describe field in error message
    */
    label: {
      type: String,
      default: null
    },
    /**
    * Sets the disabled attribute.
    */
    disabled: {
      type: Boolean,
      default: null
    },
    /**
    * Sets the field to required.
    */
    required: {
      type: Boolean,
      default: false
    },
    /**
    * An object to provide additional options to the validation wrapped. See https://vee-validate.logaretm.com/v3/api/validation-provider.html#props
    */
    veeOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    /**
    * Displays the value of the field in a span element, instead of as an input.
    */
    displayOnly: {
      type: Boolean,
      default: false
    },
    /**
    * The event for this component.
    * @values input, change
    */
    event: {
      type: String,
      default: "input"
    },
    /**
    * A pipe separated string of validation rules for the field. See https://vee-validate.logaretm.com/v3/guide/rules.html#rules
    * @example "required|min:2|max:55"
    */
    rules: {
      type: String,
      default: ""
    },
    /**
    * Prevents a field from being rendered at all when used in the `fields` prop of the `EnForm` component.
    * Can be passed a function that returns true or false for conditional validation.
    */
    renderInForm: {
      type: [Boolean, Function],
      default: true
    },
    /**
    * Prevents a field from generating a column/heading in the `EnTableBasic` component.
    * Can be passed a function that returns true or false for conditional validation.
    */
    renderInTable: {
      type: [Boolean, Function],
      default: true
    },
    /**
    * Provide a custom error messages by rule. Should be an object with keys that match the rule name and value being a string of the message to display.
    * @example { required: 'The name field is required', min: 'You must enter at least 2 characters' }
    */
    customMessages: {
      default: null,
      type: Object
    },
    /**
    * Presets the value when you don't necessarily need to use v-model
    */
    modelValue: {
      default: null
    }
  },
  data() {
    return {
      localValue: this.value,
      hasError: false,
      errorMessage: ""
    };
  },
  mounted() {
    if (this.modelValue) {
      this.displayValue = this.modelValue
    }
  },
  computed: {
    displayValue: {
      get() {
        const value = this.isValuePropSet()
          ? this.value
          : this.localValue;
        return this.format(value);
      },
      set(val) {
        const value = this.unFormat(val);

        this.$emit(this.event, value);
        this.localValue = value;
      }
    },
    validator() {
      return this.$refs.validator?.$refs?.validator || null
    },
    computedErrorMessages() {
      const requiredMsg = this.label ? {required: `The ${this.label} field is required.` } : {}
      const custom = this.customMessages ? this.customMessages : {}

      return {
        ...requiredMsg,
        ...custom
      }
    }
  },
  methods: {
    isValuePropSet() {
      return (
        !!this.$options.propsData &&
        this.$options.propsData.value !== undefined
      );
    },
    // hopefully this doesn't break anything, but will fix select inputs when value is set to null
    format(val) {
      return val === null ? "" : val;
    },
    unFormat(val) {
      return val;
    },
    validate() {
      this.validator.validate()
    },
    handleInput(e) {
      if (this.event === 'input') {
        this.displayValue = e.target.value
      }
    },
    handleChange(e) {
      if (this.event === 'change') {
        this.displayValue = e.target.value
      }
    }
  }
};

export { inputMixin as default };
