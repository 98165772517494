<template>
  <span class="input-group-inner-addon">
    <slot>
      <a
        href="#"
        :class="linkClass"
        @click.prevent="$emit('click')"></a>
    </slot>
  </span>
</template>

<script>
export default {
  name: 'EnInputInnerAddon',
  props: {
    linkClass: {
      type: String,
      default: 'icon-times-circle link-icon-only'
    }
  }
}
</script>
