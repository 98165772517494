<script>
  export default {
    name: 'EnInputGroup',
    functional: true,
    props: {
      label: '',
      horizontal: {
        type: Boolean,
        default: false
      },
      labelClass: {
        type: String,
        default: 'col-md-3'
      },
      inputClass: {
        type: String,
        default: 'col-md-9'
      },
      showRequired: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      }
    },
    render(createElement, context) {
      // console.log(context.slots());
      const slot = context.slots().default[0];
      const labelClass = context.props.horizontal ? context.props.labelClass : '';
      const label = createElement('label', {
        class: ['control-label', labelClass],
        domProps: {
          innerHTML: context.props.showRequired && context.props.required ?
                      `${context.props.label} <span>*</span>` :
                      context.props.label,
        },
        attrs: {
          for: slot?.componentOptions?.propsData?.id
        }
      });

      let input = slot

      if (context.props.horizontal) {
        input = createElement('div', {
          class: context.props.inputClass
        }, context.children);
      }
      // console.log(slot, input);
      return [label, input]
    }
  }
</script>

