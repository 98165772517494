<template>
  <div class="wysiwyg-content form-control-static" v-if="displayOnly" v-html="displayValue"></div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="rules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <div @focusout="sanitize">
      <input
        v-bind="$attrs"
        v-model="displayValue"
        @focus.stop="fakeFocus($event)"
        data-vv-validate-on="change|submit"
        type="text"
        style="
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
          transform: translateX(-100vw);
        "
        :name="name"
        :id="id || name"
        :required="required"
      />
      <VueWysiwyg
        ref="wysiwyg"
        v-model="displayValue"
        :options="options"
        :disabled="disabled"
        :placeholder="placeholder"
      />
      <div v-if="hasError" class="error-message">
        {{ errorMessage }}
      </div>
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import { VueWysiwyg } from "@front-end/vue-wysiwyg";

import inputMixin from "../utils/input-mixin.js";
export default {
  name: "EnWysiwyg",
  components: {
    EnValidationFieldWrapper,
    VueWysiwyg,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Additional customization options passed to the wrapped Wysiwyg component.
     */
    options: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  mounted() {
    if (this.modelValue) {
      this.$refs.wysiwyg.innerHTML = this.modelValue
    }
  },
  methods: {
    sanitize() {
      if (typeof this.displayValue === 'string') {
        this.displayValue = this.$refs.wysiwyg.sanitize(this.displayValue)
      }
    },
    fakeFocus(e) {
      if (e.relatedTarget.parentElement.className.indexOf('wysiwyg') === -1) {
        this.focus();
      }
    },
    focus() {
      this.$refs.wysiwyg.$refs.editor.$el.firstChild.focus();
      this.$refs.wysiwyg.focusEditor();
    }
  },
};
</script>
