<template>
  <EnValidationFieldWrapper
    :name="name"
    :required="required"
    :rules="rules"
    :veeOptions="veeOptions"
    :customMessages="computedErrorMessages"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <input
      class="form-control"
      type="hidden"
      :name="name"
      :id="id || name"
      v-model="displayValue"
      :disabled="disabled"
    />
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";

export default {
  name: "EnInputHidden",
  components: {
    EnValidationFieldWrapper,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Use v-model to bind to value of the input.
     * @model
     */
    value: {
      type: [String, Number, Boolean, Array]
    },
  }
};
</script>
