<template>
  <optgroup v-if="isGroup" :label="option.label || null" :disabled="option.disabled">
    <EnInputSelectOption v-for="opt in option.options" :key="`${name}-${opt.value}`" :option="opt"></EnInputSelectOption>
  </optgroup>
  <option
    v-else
    :value="option.value"
    :disabled="option.disabled"
  >
    {{ option.label }}
  </option>
</template>

<script>
export default {
  name: 'EnInputSelectOption',
  props: {
    option: Object,
    name: String
  },
  computed: {
    isGroup() {
      return this.option.options && this.option.options.length
    }
  }
}
</script>
