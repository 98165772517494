<template>
  <div v-if="displayOnly" class="form-control-static">{{ displayValue ? trueText : falseText }}</div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="rules"
    :customMessages="computedErrorMessages"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <EnInputCheckbox
      :name="name"
      :optionValue="optionValue || value"
      :label="inputLabel"
      :id="id || name"
      v-model="displayValue"
      :required="required"
      :disabled="disabled"
      :single="true"
      :inline="inline"
      :toggle="toggle"
      :toggle-size="toggleSize"
      :trueText="trueText"
      :falseText="falseText"
    ><!--@slot Add a tooltip component. --><template v-slot:tooltip><slot name="tooltip"/></template></EnInputCheckbox>
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import EnInputCheckbox from "./EnInputCheckbox.vue";
import inputMixin from "../utils/input-mixin.js";
export default {
  name: "EnInputBoolean",
  components: {
    EnValidationFieldWrapper,
    EnInputCheckbox,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Use v-model to bind to value of the input.
     * @model
     */
    value: {
      type: [Boolean, Array],
      default: false,
    },
    /**
     * Required when using multiple EnInputBooleans to return an array of values when not using a checkbox group.
     */
    optionValue: {
      default: null,
    },
    /**
     * Sets label next to checkbox.
     */
    inputLabel: {
      type: String,
      default: ''
    },
    /**
     * Sets 'checkbox-inline' style.
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets the display to a more stylized toggle.
     */
    toggle: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the size of the toggle.
     * @values sm, xs
     */
    toggleSize: {
      type: String,
      default: "md",
      validator(value) {
        return ['md', 'sm', 'xs'].indexOf(value) !== -1
      }
    },
    /**
     * Display text for inside the toggle, true side.
     */
    trueText: {
      type: String,
      default: 'Yes'
    },
    /**
     * Display text for inside the toggle, false side.
     */
    falseText: {
      type: String,
      default: 'No'
    }
  },
};
</script>
