<script>
import { ValidationProvider, extend } from 'vee-validate';
import validationRules from '../utils/validation-rules';
validationRules();

export default {
  name: 'EnValidationFieldWrapper',
  // functional: true,
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      required: false
    },
    mode: {
      type: String,
      default: 'lazy'
    },
    customMessages: {
      type: Object,
      default: null
    },
    veeOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    name: {
      type: String
    }
  },
  computed: {
    renderValidation() {
      return Object.keys(this.veeOptions).length !== 0 || this.validatorRules.length;
    },
    validatorRules() {
      // if required is true but rules is empty, we need to add required in
      if (this.required && this.rules === null) {
        return 'required'
      }
      if (this.required && !this.rules.includes('required')) {
        return this.rules.length ? 'required|' + this.rules : 'required'
      } else {
        return this.rules || ''
      }
    }
  },
  methods: {
    validate(e = false) {
      if (e && this.$refs && this.$refs.validator) {
        return this.$refs.validator.validate(e)
      } else {
        // validate returns a promise so if we aren't using it, want to return the same type
        return new Promise((resolve) => {
          resolve({valid: true})
        })
      }
    }
  },
  render(createElement) {
    // console.log(this);
    const self = this;
    // if (this.renderValidation) {

      return createElement(ValidationProvider, {
        ref: 'validator',
        name: 'ValidationProvider',
        // passing some default props to ValidationProvider + any overrides in veeOptions
        props: {
          vid: this.name,
          ...this.$options.propsData,
          rules: this.validatorRules,
          ...this.veeOptions,
        },
        class: {
          'has-error': false
        },
        scopedSlots: {
          default: (props) => {
            self.$parent.hasError = props.errors.length;
            self.$parent.errorMessage = props.errors[0];
            self.$parent.validate = props.validate
            return self.$slots.default
          }
        }
      }, this.$slots.default);
    // } else {

    //   return createElement('span', this.$slots.default)
    // }
  }
}

</script>
