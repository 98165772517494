import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules.js';
import errorMessages from './error-messages.json';

export default () => {
  Object.keys(rules).forEach(rule => {

    if (rule === 'email') {
        extend("email", {
          ...rules[rule],
          message: errorMessages.messages[rule],
          validate(val) {
            const isValid = (/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,63}$/).test(val)
            return isValid;
          }
        });
    } else {

      extend(rule, {
        ...rules[rule],
        message: errorMessages.messages[rule]
      });
    }
  });

  extend('ssn_only', {
    validate(val) {
      if (val && val.length < 3) {
        return false
      }
      if (val && val.length > 3) {
        const replaceDashes = val.replace(/-/g, '')
        // if (replaceDashes.startsWith('9')) {
        //   this.message = `SSN cannot start with 9.`
        //   return false
        // }
        const firstThree = replaceDashes.substring(0, 3);

        switch (firstThree) {
          case '000':
          case '666':
            this.message = `SSN cannot start with ${firstThree}.`;
            return false
        }
        const nextTwo = replaceDashes.substring(3,5)

        if (nextTwo === '00') {
          this.message = `SSN second group cannot be 00.`
          return false
        }
        const lastFour = replaceDashes.substring(5,9)
        if (lastFour === '0000') {
          this.message = `SSN last four cannot be 0000.`
          return false
        }
      }
      return true
    },
    message: 'This is not a valid SSN.'
  })

  extend('is_date', {
    validate(val) {
      const parsed = Date.parse(val);
      const dateArray = val.split("/");

      if (dateArray.length < 3) {
        this.message = "This is not a valid date.";
        return false;
      }
      dateArray[2] = dateArray[2].substring(0,4)
      if (
        dateArray.length === 3 && dateArray[2].length !== 4
        ) {
          this.message = 'You must enter a 4 digit year.'
        return false;
      }
      return true
    },
    message: 'This is not a valid date.'
  })

  extend('min_age', {
    validate(val, params) {
      const parsed =  Date.parse(val);
      const birthdate = new Date(parsed);
      if (birthdate) {
        const ageDifMs = Date.now() - birthdate.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        const age = parseInt(ageDate.getUTCFullYear() - 1970);
        return age >= parseFloat(params.min)
      }
      return false
    },
    params: [
      {name: 'min'}
    ],
    message: 'Age must be a minimum of {min}.'
  })

  extend('min_date', {
    validate(val, params) {
      const parsed =  Date.parse(val);
      const date = new Date(parsed);
      if (date) {
        const minDate = new Date(params.min);
        return date >= minDate
      }
      // return false
    },
    params: [
      {name: 'min'}
    ],
    message: 'Date must be on or after {min}.'
  })

  extend('max_date', {
    validate(val, params) {
      const parsed =  Date.parse(val);
      const date = new Date(parsed);
      if (date) {
        const maxDate = new Date(params.min);
        return date <= maxDate
      }
      // return false
    },
    params: [
      {name: 'min'}
    ],
    message: 'Date must be on or before {min}.'
  })

}
