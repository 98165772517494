<template>
  <div class="app main-content">
    <div class="main-content-area">
      <nav class="navbar navbar-default">
        <ul class="nav navbar-nav">
          <li v-for="route in routes" :key="route.path"><router-link :to="route.path">{{ route.name }}</router-link></li>
        </ul>
      </nav>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { routes } from './router'
export default {
  name: 'App',
  data() {
    return {
      routes
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');
</style>
