<script>
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'EnValidationWrapper',
  functional: true,
  components: {
    ValidationObserver
  },
  props: {
    tag: {
      default: 'div'
    },
    rules: {
      default: ''
    },
    mode: {
      default: 'eager'
    },
    veeOptions: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  render(createElement, context) {
    // console.log(context);
    return createElement(ValidationObserver, {
      ...context.data,
      props: {
        tag: context.props.tag,
        slim: true,
        mode: context.props.mode,
        rules: context.props.rules,
        ...context.props.veeOptions
      }
    }, context.children)
  }
}
</script>
