<template>
  <div v-if="displayOnly" class="form-control-static">
    <slot name="display" :display-value="displayValue" :value="value">
      {{ displayValue }}
    </slot>
  </div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="computedRules"
    :customMessages="phoneErrorMessages"
    :mode="'eager'"
    :veeOptions="computedVeeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <input
      v-bind="$attrs"
      class="form-control"
      type="tel"
      :name="name"
      :id="id || name"
      :required="required"
      :placeholder="placeholder"
      :disabled="disabled"
      v-model="displayValue"
      v-maska="maskaObj"
      :data-maska="activeMask"
      data-maska-tokens="0:[0-9\s]:multiple|?:[0-9+]"
      :data-unmasked="maskaObj.unmasked"
      @paste="onPaste"
      @blur="handleBlur"
      autocomplete="off"
    />
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import { vMaska, Mask } from "maska"
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";

export default {
  name: "EnInputPhone",
  directives: {
    maska: vMaska
  },
  components: {
    EnValidationFieldWrapper,
  },
  mixins: [inputMixin],
  props: {
    resetValidationOnMount: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      maskaObj: {
        masked: '',
        unmasked: ''
      },
      activeMask: '',
      baseMask: '?#',
      uSMask: '(###) ###-####x0',
      foreignMask: '?#0 #0',
      hadError: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.resetValidationOnMount) {
        this.validator.reset()
      }
    })
  },
  watch: {
    displayValue: {
      immediate: true,
      handler(val, prev) {
        if (val) {
          if (val.length === 1) {
            this.activeMask = this.baseMask
          } else if (val.includes('+')) {
            this.activeMask = this.foreignMask
          } else {
            this.activeMask = this.uSMask
          }
        } else {
          this.activeMask = this.baseMask
        }
      }

    },
    maskedVal(val) {
      this.displayValue = val
      this.validator?.syncValue(this.maskaObj?.unmasked.replace(/\s/g, '') || val)
      if (this.hasError || this.hadError) {
        this.validator?.validate()
      }
    },
    displayOnly: {
      handler(val) {
        if (val) {
          const mask = new Mask({
            mask: this.activeMask,
            tokens: {
              0: {
                pattern: /[0-9\s]/,
                multiple: true
              },
              '?': {
                pattern: /[0-9+]/
              }
            }
        })
          this.maskaObj.masked = mask.masked(this.value || '')
          this.maskaObj.unmasked = mask.unmasked(this.value || '')
        }
      },
      immediate: true
    },
    hasError(val) {
      if (val) {
        this.hadError = true
      }
    }
  },
  computed: {
    computedVeeOptions() {

      return {
        ...this.veeOptions,
        detectInput: false
      }
    },
    maskedVal() {
      return this.maskaObj?.masked
    },
    isUsNumber() {
      return this.activeMask === this.uSMask
    },
    isForeign() {
      return this.activeMask === this.foreignMask
    },
    computedRules() {
      let rules = 'min:10'
      // if (this.isUsNumber) {
      //   rules = 'min:10'
      // }

      if (this.isForeign) {
        rules = 'min:10|max:15'
      }

      if (this.rules.length) {
        rules = `${rules}|${this.rules}`
      }
      return rules
    },
    phoneErrorMessages() {
      const length = {min: 'Number is not long enough.'}

      return {
        ...length,
        ...this.computedErrorMessages
      }
    }
  },
  methods: {
    format(val) {
      return this.maskaObj?.masked || val
    },
    unFormat(val) {
      if (this.isForeign) {
        return this.maskaObj?.unmasked || val
      }
      return this.maskaObj?.unmasked?.replace(/\s/g, '') || val
    },
    onPaste() {
      setTimeout(() => {
        this.validate()
      })
    },
    handleBlur() {
      this.validator?.validate()
    }
  },
};
</script>
