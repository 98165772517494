<template>
  <div class="checkbox-tree">
    <div class="checkbox-tree-select-all">
      <EnInputCheckbox :single="true" :label="selectAllLabel" @input="selectAllHandler" />
    </div>
    <EnInputCheckboxTreeOptions v-for="(option, i) in options" :key="i" :label="option.label" :options="option.children || false" />
  </div>
</template>

<script>
import EnInputCheckbox from './EnInputCheckbox.vue';
import EnInputCheckboxTreeOptions from './EnInputCheckboxTreeOptions.vue';

export default {
  name: 'EnInputCheckboxTree',
  components: {
    EnInputCheckbox,
    EnInputCheckboxTreeOptions
  },
  props: {
    value: Boolean,
    options: {
      type: Array
    },
    selectAllLabel: {
      type: String,
      default: 'Select all'
    }
  },
  data() {
    return {

    }
  },
  computed: {
    displayValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit(this.event, val);
        return val;
      }
    }
  },
  methods: {
    selectAllHandler(e) {
      console.log(e);
    }
  }
}
</script>

<style lang="scss">
  .checkbox-tree {

  }
</style>
