<script>
import EnInputGroup from './EnInputGroup.vue';

export default {
  name: 'EnFormGroup',
  components: {
    EnInputGroup,

  },
  props: {
    /**
     * A single field object to dynamically create the input inside of the form group.  Key-value pairs can be any valid props for input components.
     */
    field: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * @private
     */
    value: '',
    /**
     * @private
     */
    inputGroupProps: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * Displays asterisk (*) next to the label to indicate a required field.
     */
    showRequired: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    /**
     * Label text to display.
     */
    label: {
      type: String,
      default: ''
    },
    displayOnly: {
      type: Boolean,
      defualt: false
    }
  },
  render(createElement) {
    let innerField;
    const self = this;

    let additionalProps = {}

    const formGroupClasses = ['form-group']

    if (this.$slots.default) {
      const input = this.$slots.default[0]
      let inputComponent = input

      if (!input.tag.includes('EnInput') && !input.tag.includes('EnWysiwyg') && !input.tag.includes('EnTypeahead') ) {
        throw new Error('This slot only supports a single EnInput prefixed component')
      }

      if (!this.$vnode?.data?.staticClass) {
        formGroupClasses.push('col-12')
      }

      let label = ''
      if (this.label) {
        const labelClasses = ['control-label']
        if (this.inputGroupProps.labelClass && this.inputGroupProps.inputClass) {
          labelClasses.push(this.inputGroupProps.labelClass)
          inputComponent = createElement('div',
            {
              class: this.inputGroupProps.inputClass
            },
            [input]
          )
        }

        const labelNodes = this.$slots.label ? this.$slots.label :
            [
              self.label,
              self.showRequired && (input?.componentOptions?.propsData?.required === true || input?.componentOptions?.propsData?.required === "")  ?
                        createElement('span', ' *') :
                        "",
              this.$slots['label-inner']
            ]

        label = createElement('label', {
          class: labelClasses,
          attrs: {
            for: input?.componentOptions?.propsData?.id
          }
        }, [labelNodes]);
      }
      innerField = [label, inputComponent]
    } else {

      switch (self.field.type) {
        case 'checkboxes':
          additionalProps = self.inputGroupProps;
          innerField = inputGroup('en-input-checkbox-group', true);
          break;
        case 'radio':
          additionalProps = self.inputGroupProps;
          innerField = inputGroup('en-input-radio-group', true);
          break;
        case 'hidden':
          return inputGroup('en-input-hidden', true);
        case 'wysiwyg':
          innerField = inputGroup('en-wysiwyg');
          break;
        case 'typeahead':
          additionalProps = self.inputGroupProps;
          innerField = inputGroup('en-typeahead');
          break;
        case undefined:
          innerField = inputGroup('en-input-text');
          break;
        default:
          innerField = inputGroup(`en-input-${self.field.type}`);
          break;
      }
    }

      if (this.field.formGroupClass) {
        formGroupClasses.push(this.field.formGroupClass)
      }

      const returnedComponent = createElement('div', {
        class: formGroupClasses
      }, innerField)

      function inputGroup(resolvedComponent, noWrap) {
        const inputComponent = createElement(resolvedComponent, {
          props: {
            displayOnly: self.displayOnly,
          ...self.field,
          ...additionalProps
          },
          domProps: {
            value: self.field.displayValue
          },
          on: {
            input: function (event) {
              self.$emit('input', event)
            }
          },
          slot: 'default'
        });

        if (noWrap) {
          return [inputComponent]
        } else {
          return createElement(EnInputGroup, {
            props: {
              label: self.field.label,
              required: self.field.required,
              ...self.inputGroupProps
            }
          }, [inputComponent]);
        }
      }

      return returnedComponent;

  }
}
</script>
