<template>
  <button ref="submit" @click="clickHandler" :disabled="isSubmitting || disabled" class="btn" :class="btnClass">
    <slot v-if="!isSubmitting">Submit</slot>{{ isSubmitting ? isSubmittingText : '' }}<span v-if="isSubmitting" class="spinner icon-redo"></span>
  </button>
</template>

<script>

export default {
  name: 'EnButtonSubmit',
  props: {
    /**
     * Disables button and displays a spinner icon.
     */
    isSubmitting: {
      type: Boolean,
      default: false
    },
    /**
     * Disables button.
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Text that displays while button is submitting.
     */
    isSubmittingText: {
      type: String,
      default: 'Submitting'
    },
    /**
     * Default button CSS class.
     */
    btnClass: {
      type: String,
      default: 'btn-success'
    }
  },
  methods: {
    clickHandler() {
      if (this.$parent && typeof this.$parent.handleSubmit === typeof(Function)) {
        this.$parent.handleSubmit(this.success)
      } else {
        this.success()
      }
    },
    success() {
      this.$emit('click');
    }
  }
}
</script>
<style lang="scss" scoped>
  .spinner {
    margin-left: 8px;

    &::before {
      animation: spinning .5s linear infinite;
    }
  }
  @keyframes spinning {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
