<template>
  <div v-if="displayOnly && pills" class="form-control-static">
    <span v-for="option in selectedOptions" :key="option.value" class="label" :style="{backgroundColor: option.labelColor}">{{ option.label }}</span>
  </div>
  <div v-else-if="displayOnly" class="form-control-static">
    <slot name="display" :selections="selectedOptions">
      <span v-for="option in selectedOptions" :key="option.value" class="list-comma-separated">{{ option.label }}</span>
    </slot>
  </div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="optionsRules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <select
      class="form-control"
      :name="name"
      :id="id || name"
      v-model="displayValue"
      :required="required"
      :disabled="disabled"
    >
      <slot>
        <EnInputSelectOption
          v-for="option in mappedOptions"
          :key="`${name}-${option.value || option.label}`"
          :name="name"
          :option="option"
        >
        </EnInputSelectOption>
      </slot>
    </select>
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import EnInputSelectOption from "./EnInputSelectOption.vue"
import inputMixin from "../utils/input-mixin.js";
import optionsMixin from "../utils/options-mixin.js";
export default {
  name: "EnInputSelect",
  components: {
    EnValidationFieldWrapper,
    EnInputSelectOption,
  },
  mixins: [inputMixin, optionsMixin],
  props: {
    /**
     * Use v-model to bind to value of the input.
     * @model
     */
    value: {
      default: "",
    },
    /**
     * Array of option objects with label and value keys.
     * @example [{label: 'Red', value: 'red'}, {label: 'Blue', value: 'blue'}]
     */
    options: {
      type: Array,
      default() {
        return [
          {
            label: "No options passed",
            value: "",
          },
        ];
      },
    },
    /**
     * Styles display value as pills.
     */
    pills: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
