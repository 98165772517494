<template>
  <div v-if="displayOnly" class="form-control-static">{{ displayValue }}</div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="rules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <input
      class="form-control"
      type="email"
      :name="name"
      :id="id || name"
      :required="required"
      :placeholder="placeholder"
      v-model.trim="displayValue"
      :disabled="disabled"
      autocomplete="off"
    />
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";
export default {
  name: "EnInputEmail",
  components: {
    EnValidationFieldWrapper,
  },
  mixins: [inputMixin],
  props: {
    rules: {
      default: 'email'
    }
  }
};
</script>
