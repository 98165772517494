import Vue from 'vue';
import App from "./App.vue";
import router from "./router";
import EnComponents from '@front-end/en-components'
import 'bootstrap/dist/css/bootstrap.css';
import '@front-end/en-style/src/scss/main.scss';
import '@front-end/en-components/dist/en-components.css'
import '@front-end/vue-wysiwyg/dist/vue-wysiwyg.css'
Vue.config.productionTip = false;
EnComponents.install(Vue)

import * as components from "@/lib-components/index";
Object.entries(components).forEach(([componentName, component]) => {
  Vue.component(componentName, component);
});

import EnWysiwyg from "@/lib-components/EnWysiwyg"
Vue.component('EnWysiwyg', EnWysiwyg)

new Vue({
  components: { App },
  template: "<App/>",
  router
}).$mount("#app");
