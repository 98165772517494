<template>
  <div class="name">
    <button class="btn btn-primary" @click="setTextVal">Set text</button>
    <EnForm
      @submit="handleSubmit"
      :prevent-multi-submit="false"
      >

      <template #fields>

        <EnFormGroup label="Text">
          <EnInputText
            v-model="model.textVal"></EnInputText>
        </EnFormGroup>
        <EnFormGroup label="File">
          <EnInputFile
            required
            :limit="3"
            name="Logo"></EnInputFile>
        </EnFormGroup>
      </template>
    </EnForm>
  </div>
</template>

<script>
export default {
  name: 'FileUploadView',
  data() {
    return {
      model: {
        textVal: "something"
      }
    }
  },
  methods: {
    setTextVal() {
      this.model.textVal = "something else?"
    },
    handleSubmit(data) {
      console.log(data)
    }
  }
}
</script>

<style lang="scss">
.name {

}
</style>
