<template>
  <div v-if="displayOnlyAge" class="form-control-static">{{ computedAge || ''  }}</div>
  <div v-else-if="displayOnly" class="form-control-static">{{ displayValue }}</div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="computedRules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <input
      class="form-control"
      type="text"
      :name="name"
      :id="id || name"
      :required="required"
      :placeholder="placeholder"
      v-model="displayValue"
      :disabled="disabled"
      v-maska
      data-maska="#0/#0/####"
      data-maska-tokens="0:[0-9]:optional"
      data-maska-eager
      @blur="handleBlur"
      autocomplete="off"
    />
    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import { vMaska } from "maska"

import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import inputMixin from "../utils/input-mixin.js";
export default {
  name: "EnInputDate",
  directives: {
    maska: vMaska
  },
  components: {
    EnValidationFieldWrapper,
  },
  mixins: [inputMixin],
  props: {
    /**
     * Sets validation for a minimum age calculated from todays date and the date entered.
     */
    minAge: {
      type: Number,
      default: null
    },
    /**
     * Sets validation for minimum date.
     */
    minDate: {
      type: String,
      default: null
    },
    /**
     * Sets validation for maximum date.
     */
    maxDate: {
      type: String,
      default: null
    },
    placeholder: {
      default: 'mm/dd/yyyy'
    },
    /**
     * Sets input to display only and will display the age based on provided date.
     */
    displayOnlyAge: {
      type: Boolean,
      default: false,
    },
    value: {
      default: ''
    }
  },
  mounted() {
    this.displayValue = this.value === undefined ? '' : this.parsedDateReadable
  },
  computed: {
    computedRules() {
      let rules = `is_date`
      if (this.minAge !== null) {
        rules = `${rules}|min_age:${this.minAge}`
      }

      if (this.minDate) {
        rules = `${rules}|min_date:${this.minDate}`
      }

      if (this.maxDate) {
        rules = `${rules}|max_date:${this.maxDate}`
      }

      if (this.rules.length) {
        rules = `${rules}|${this.rules}`
      }
      return rules
    },
    validFormat() {
      return this.validYear(this.displayValue)
    },
    parsedDate() {
      return Date.parse(this.unixAdapter(this.displayValue))
    },
    parsedDateReadable() {
      return this.formatDate(this.parsedDate)
    },
    computedAge() {
      return this.getAge(this.parsedDate)
    }
  },
  methods: {
    formatDate(dateString) {

      return new Date(dateString).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
      })
    },
    validYear(date) {
      if (date) {
        const dateArray = this.formatDate(date).split("/");
        if (
          dateArray.length !== 3 || dateArray[2].length !== 4
        ) {
          return false
        }
        return true
      }
      return false
    },
    getAge(date) {
      if (!this.validYear(date)) {
        return null
      }
      const birthdate = new Date(date)

      if (birthdate) {
        const ageDifMs = Date.now() - birthdate.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.floor(ageDate.getUTCFullYear() - 1970);
      }
      return null
    },
    handleBlur() {
      this.validate().then(({valid}) => {

        if (valid) {
          this.displayValue = this.parsedDateReadable
        }
      })
    },
    // use until dotnet 8
    unixAdapter(date) {
      if (typeof date === 'string') {
        return date.replace(/AM|PM/g, " $&");
      }

      return date
    }
  }

};
</script>
