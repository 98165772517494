<template>
  <component
    :is="disableForm ? 'fieldset' : 'form'"
    :disabled="disableForm ? true : null"
    ref="form"
    :class="{ 'form-horizontal': horizontal }"
    v-on:submit.prevent="submitForm"
    novalidate
    autocomplete="off"
  >
    <EnValidationWrapper
      ref="validationWrapper"
      v-slot="{ invalid, validated }"
      :class="{ 'flex-row': !horizontal }"
    >
      <slot name="fields">
        <slot v-for="(field, i) in returnFields" :name="field.name" :field="field">
          <EnFormGroup
            :key="i"
            :field="field"
            v-model="returnFields[i].value"
            :horizontal="horizontal"
            :inputGroupProps="inputGroupProps"
            :class="field.formGroupClass ? '' : formGroupClass"
            :displayOnly="displayOnly"
            :disabled="disableForm"
          />
        </slot>
      </slot>

      <div class="form-group col-12">
        <div
          class=""
          :class="{ [offsetClass]: horizontal, [inputClass]: horizontal }"
        >
          <div v-if="invalid && validated" class="error-message">
            {{ errorMessage }}
          </div>
          <div class="btn-container margin-top">
            <slot name="buttons">
              <EnButtonSubmit
                ref="submitButton"
                v-show="renderSubmitButton"
                :isSubmitting="isSubmitting"
                class="submit-button"
                :btnClass="submitClass"
                :disabled="disableSubmit"
                >{{ submitText }}</EnButtonSubmit
              >

              <a v-if="renderCancelButton"
                href="#"
                @click.prevent="$emit('cancel')"
                class="btn"
                :class="cancelClass">{{ cancelText }}</a>
            </slot>
          </div>
        </div>
      </div>
    </EnValidationWrapper>
  </component>
</template>

<script>
import EnValidationWrapper from "./EnValidationWrapper.vue";
import EnButtonSubmit from "./EnButtonSubmit.vue";
import EnFormGroup from "./EnFormGroup.vue";

export default {
  name: "EnForm",
  components: {
    EnValidationWrapper,
    EnButtonSubmit,
    EnFormGroup
  },
  props: {
    /**
     * An array of objects containing the entries for the form. Each object will map to the props of any 'EnInput*' component.
     */
    fields: {
      type: Array
      // required: true
    },
    /**
     * Sets the layout of the fields in the form by adding the 'form-horizontal' css class
     */
    horizontal: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the readable text for the default submit button.
     */
    submitText: {
      type: String,
      default: "Submit"
    },
    /**
     * Sets the CSS class for the default submit button.
     */
    submitClass: {
      type: String,
      default: "btn-success"
    },
    /**
     * Sets the readable text for the default cancel button.
     */
    cancelText: {
      type: String,
      default: "Cancel"
    },
    /**
     * Sets the CSS class for the default cancel button.
     */
    cancelClass: {
      type: String,
      default: "btn-link"
    },
    /**
     * Sets the bottom error message when a form is submitted with validation errors.
     */
    errorMessage: {
      type: String,
      default: "Please fix all errors before submitting."
    },
    /**
     * Sets the class used for the label column when using horizontal layout.
     */
    labelClass: {
      type: String,
      default: "col-md-3"
    },
    /**
     * Sets the class used for the input column when using horizontal layout.
     */
    inputClass: {
      type: String,
      default: "col-md-9"
    },
    /**
     * Displays asterisk (*) next to required field labels
     */
    showRequired: {
      type: Boolean,
      default: false
    },
    /**
     * Whether or not to render the submit button responsible for the submit event
     * for the form.
     */
    renderSubmitButton: {
      type: Boolean,
      default: true
    },
    /**
     * Whether or not to render the cancel button responsible for the cancel event
     * for the form.
     */
    renderCancelButton: {
      type: Boolean,
      default: false
    },
    /**
     * Whether or not to render the 'loading spinner' icon indicating that the form
     * is in the middle of a submit process.
     */
    isSubmitting: {
      type: Boolean,
      default: false
    },
    /**
     * Adds double-click protection to form submit button, in the form of preventing a second submit.  Form will need to be re-rendered via a page reload or key change in order to reset.
     */
    preventMultiSubmit: {
      type: Boolean,
      default: true
    },
    /**
     * Sets class for each dynamically created form group.  Use this to create additional columns of fields.
     * @example 'col-md-6' will created 2 columns of fields, 'col-md-4' will create 3 columns of fields
     */
    formGroupClass: {
      type: String,
      default: "col-12"
    },
    /**
     * Sets all inputs in the fields json to display only.
     */
    displayOnly: {
      type: Boolean,
      default: false
    },
    /**
     * Disables submit button and form submit event.
     */
    disableSubmit: {
      type: Boolean,
      default: false
    },
    /**
     * Disables form and removes submit button.
     */
    disableForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      didSubmit: false
    };
  },
  computed: {
    returnFields: {
      get() {
        return this.fields && this.fields.filter(field => {
          if (typeof field.renderInForm === 'function') {
            return field.renderInForm()
          }
          return field.renderInForm !== false
        });
      },
      set(val) {
        this.$emit(this.event, val);
        return val;
      }
    },
    offsetClass() {
      const splitLabel = this.labelClass.split("-");
      splitLabel.push(splitLabel[2]);
      splitLabel[2] = "offset";
      return splitLabel.join("-");
    },
    inputGroupProps() {
      return {
        horizontal: this.horizontal,
        showRequired: this.showRequired,
        labelClass: this.labelClass,
        inputClass: this.inputClass
      };
    },
    validationObserver() {
      return this.$refs?.validationWrapper
    },
    formState() {
      return this.validationObserver?.flags
    }
  },
  methods: {
    getFormData() {
      if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
      }
      const returnData = {},
        form = this.$refs.form,
        namedFields = form.querySelectorAll("[name]");

      // const data = new FormData(form);
      // const objectData = Object.fromEntries(data.entries())
      if (this.returnFields) {
        this.returnFields.map(field => {
          returnData[field.name] = field.value;
        });
        return returnData;
      }

      namedFields.forEach(field => {
        const { value, name, type } = field;

        if (!name) {
          // return
        } else if (type === "radio") {
          if (field.checked) {
            returnData[name] = value;
          }
        } else if (type === "checkbox") {
          if (
            returnData.hasOwnProperty(name) &&
            typeof returnData[name] === "object" &&
            field.checked
          ) {
            returnData[name].push(value);
          } else if (form.querySelectorAll(`[type='checkbox'][name='${name}']`).length > 1) {
            if (field.checked) {
              returnData[name] = [value];
            }
          } else {
            returnData[name] = field.checked ? field.value === 'on' || field.value === 'false' ? true : field.value : false;
          }
        } else if (type === 'hidden' && returnData[name]) {
          // return
        } else if (field.dataset.unmasked) {
          returnData[name] = field.dataset.unmasked;
        } else if (type === 'file') {
          returnData[name] = field.files;
        } else {
          returnData[name] = value;
        }
      });
      // console.log(returnData);
      return returnData;
    },
    /**
     * Returns a boolean for if the form is currently showing invalid.
     * @public
     */
    hasError() {
      return this.validationObserver.flags.invalid;
    },
    /**
     * Dynamically validate the form.
     * @public
     */
    async validate() {
      await this.validationObserver.validate()
    },
    /**
     * Used to dynamically submit the form, usually from another component using a ref to this component.
     * @public
     */
    async submitForm() {
      if (this.disableForm || this.disableSubmit) {
        return
      }

      await this.validate();
      if (this.hasError() || this.didSubmit) {
        return;
      }
      if (this.preventMultiSubmit) {
        this.didSubmit = true
      }

      const data = this.getFormData();
      /**
       * Submit event.
       */
      this.$emit("submit", data, this.resetFormState);
    },
    /**
     * Resets the validation state of the form, useful for creating a fresh set of validation flags.
     * @public
     */
    resetFormState() {
      this.didSubmit = false
      return this.validationObserver.reset()
    },
    /**
     * Sets field error messages based on key value pairs of field name and array of string error messages.
     * @public
     */
    setErrors(errorObject) {
      return this.validationObserver.setErrors(errorObject)
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
