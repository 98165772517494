<template>
  <div class="masked">
    <EnForm ref="form" @submit="handleSubmit">
      <template #fields>
        <div class="col-12">

          <div class="display-flex form-group">
            <div class="col-xs-6">
              <label for="">SSN Only</label>
              <EnInputSsn v-model="ssnVal" name="SSN">

              </EnInputSsn>
            </div>
            <div class="col-xs-6">
              <label for="">Emitted value</label>
              <pre>{{ ssnVal }}</pre>
            </div>
          </div>
          <div class="display-flex form-group">
            <div class="col-xs-6">
              <label for="">TIN</label>
              <EnInputSsn v-model="ssnTinVal" allowEinFormat assumeTin name="TIN">

              </EnInputSsn>
            </div>
            <div class="col-xs-6">
              <label for="">Emitted value</label>
              <pre>{{ ssnTinVal }}</pre>
            </div>
          </div>
          <div class="display-flex form-group">
            <div class="col-xs-6">
              <label for="">Phone</label>
              <EnInputPhone v-model="phoneVal" name="Phone" rules="max:10">

              </EnInputPhone>
            </div>
            <div class="col-xs-6">
              <label for="">Emitted value</label>
              <pre>{{ phoneVal }}</pre>
            </div>
          </div>

          <div class="display-flex form-group">
            <div class="col-xs-6">
              <label for="">Custom</label>
              <EnInputText v-model="customVal" :maskOptions="{mask: '#00.#00.#00.#00', tokens: '0:[0-9]:optional' }" name="Custom">

              </EnInputText>
            </div>
            <div class="col-xs-6">
              <label for="">Emitted value</label>
              <pre>{{ customVal }}</pre>
            </div>
          </div>
        </div>
      </template>
    </EnForm>
    <pre v-if="formData">{{ formData }}</pre>
  </div>
</template>

<script>
export default {
  name: 'Masked',
  data() {
    return {
      ssnVal: "",
      ssnTinVal: "234233445",
      phoneVal: "3013254068",
      customVal: null,
      formData: {}
    }
  },
  mounted() {
    setTimeout(() => {
      this.ssnTinVal = ""
      this.ssnTinVal = "551234567"
      // this.$refs.form.validate()
    }, 1500)
  },
  methods: {
    handleSubmit(d, reset) {
      console.log(d)
      this.formData = d
      reset()
    }
  }
}
</script>

<style lang="scss">
.selects {

}
</style>
